import gql from 'graphql-tag';

const getPulseAnalytics = gql`
  query pulseAnalytics($tenantId: ID!, $startDate: String!, $endDate: String!, $groupBy: String) {
    pulseAnalytics(tenantId: $tenantId, startDate: $startDate, endDate: $endDate, groupBy: $groupBy) {
      engagementScore
      engagementExplanation
      engagementCategories {
        id
        name
        score
      }
      enpsScore
      enpsExplanation
      enpsDetails {
        detractors
        promoters
        passives
        skipped
      }
      participationScore
      participationExplanation
      totalSurveys
      totalResponses
      uniqueRespondedPeopleCount
      datePoints
      rollingEngagementScore {
        date
        score
        startDate
        endDate
      }
      rollingParticipationScore {
        date
        score
        startDate
        endDate
      }
    }
  }
`;

export default getPulseAnalytics;
