import gql from 'graphql-tag';

const updatePulseCategory = gql`
  mutation updatePulseCategory($tenantId: ID!, $id: ID!, $name: String!, $isEnabled: Boolean!) {
    updatePulseCategory(tenantId: $tenantId, id: $id, name: $name, isEnabled: $isEnabled) {
      id
      name
      isEnabled
    }
  }
`;

export default updatePulseCategory;
