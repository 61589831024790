import gql from 'graphql-tag';

const updateMemberChannelPermissions = gql`
  mutation updateMemberChannelPermissions(
    $memberId: ID!
    $isChannelAdmin: Boolean!
    $workspaceId: ID!
  ) {
    updateMemberChannelPermissions(
      memberId: $memberId
      isChannelAdmin: $isChannelAdmin
      workspaceId: $workspaceId
    ) {
      id
      permissions {
        channelAdminWorkspaces
      }
    }
  }
`;

export default updateMemberChannelPermissions;
