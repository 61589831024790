import gql from 'graphql-tag';

const addPulseToSubscription = gql`
  mutation addPulseToSubscription($tenantId: ID!) {
    addPulseToSubscription(tenantId: $tenantId) {
      id
      quantity
      interval
      renewDate
      canceledAt
      status
      planMoney
      totalAfterDiscount
      amountOff
      addOns {
        id
        name
        interval
      }
    }
  }
`;

export default addPulseToSubscription;