import gql from 'graphql-tag';

const getCurrentUser = gql`
  query getCurrentUser {
    currentUser {
      id
      firstName
      lastName
      fullName
      headline
      photoUrl
      bio
      email
      isReceivedFeedback
      personId
      userHash
      slackHandle
      pickedSkillDetails {
        skill {
          id
          name
          slug
        }
        average
        numFeedbacks
      }
      notificationSettings {
        email {
          label
          value
          isSet
        }
      }
      tenants {
        id
        name
        photoUrl
        tier
        expiresInDays
        isEnterprise
        isTrial
        isFree
        isAdmin
        isOwner
        type
        subscriptions
        activeMembersCount
        stripeCustomerId
        stripeDowngradeSubscriptionId
        rewardsSettings {
          isRewardsActive
          isStripePaymentFailed
          maxAllotedCoinPerRitualPerMember
          allowanceResetFrequency
          disabledRewardTypes
          autoApprove
          notifyAdmins
          nextAllowanceResetDate
        }
        hasPulse
        hrisSettings {
          status
          providerDisplayName
          providerIcon
          syncingProps
          lastSync {
            date
            status
          }
        }
        pulseSettings {
          isPulseEnabled
          isPulseActive
          isPulseUnlocked
          frequency
          questionCount
          eNpsFrequency
          eNpsEnabled
          adminNotifications {
            newFeedback
            digest
          }
          notifyMembers
          coinsPerSurvey
          excludeMembers
          launchSurveysInstantly
          sendReminders
          sendToAll
          recipients
          rewardsActive
        }
        rewardsFundingSettings {
          enabled
          status
          balance
          threshold
          notifyMembers
        }
        workspaces {
          id
          name
          slackWorkspace {
            id
            primaryChannel
            primaryChannelId 
          }
          teamsWorkspace {
            id
            channelName
            channelId
          }
        }
      }
    }
  }
`;

export default getCurrentUser;
