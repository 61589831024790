import gql from 'graphql-tag';
import { REWARD_REQUEST_FRAGMENT } from './fragments';

const declineReward = gql`
  ${REWARD_REQUEST_FRAGMENT.fragment}

  mutation declineReward($rewardRequestId: ID!, $workspaceId: ID!) {
    declineReward(rewardRequestId: $rewardRequestId, workspaceId: $workspaceId) {
      ...${REWARD_REQUEST_FRAGMENT.name}
    }
  }
`;

export default declineReward;