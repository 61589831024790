import gql from 'graphql-tag';

const createStripeSubscription = gql`
  mutation createStripeSubscription($workspaceId: ID!, $priceId: ID!, $addOns: [String], $totalMembers: Int!, $promocodeId: String) {
    createStripeSubscription(workspaceId: $workspaceId, priceId: $priceId, addOns: $addOns, totalMembers: $totalMembers, promocodeId: $promocodeId) {
      id
      clientSecret
      status
      totalAfterDiscount
    }
  }
`;

export default createStripeSubscription;