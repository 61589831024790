import gql from 'graphql-tag';

const loginWithUserToken = gql`
  mutation loginWithUserToken($token: String!) {
    login(authProvider: {
      userIdToken: {
        token: $token
      }
    }) {
      isNewRecord
      redirectToWorkspaceId
      redirect
      user {
        id
        email
      }
    }
  }
`;

export default loginWithUserToken;
