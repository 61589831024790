import gql from 'graphql-tag';

const workspaceAnalytics = gql`
  query workspaceAnalytics($workspaceId: ID!, $startDate: String!, $endDate: String!, $groupBy: String!) {
    workspaceAnalytics(workspaceId: $workspaceId, startDate: $startDate, endDate: $endDate, groupBy: $groupBy) {
      participation
      topFeedbackGivers {
        userId
        fullName
        photoUrl
        feedbacksGiven
        feedbacksReceived
        kudosGiven
        kudosReceived
      }
      topFeedbackReceivers {
        userId
        fullName
        photoUrl
        feedbacksGiven
        feedbacksReceived
        kudosGiven
        kudosReceived
      }
      totalKudosGiven
      totalKudosReceived
      activeMembersCount
      datePoints
      kudosSentList {
        date
        kudosCount
        startDateOfDuration
        endDateOfDuration
      }
    }
  }
`;

export default workspaceAnalytics;
