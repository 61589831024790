import gql from 'graphql-tag';

const getSurveyResults = gql`
  query getSurveyResults($tenantId: ID!, $surveyId: ID!) {
    surveyResults(tenantId: $tenantId, surveyId: $surveyId) {
      responseCount
      totalSent
      uniqueSent
      responses {
        questionId
        responseCount
        comments {
          message
          createdAt
        }
        results {
          answer 
          count
          createdAt
        }
      }
      enps {
        enpsScore
        enpsExplanation
        enpsDetails {
          promoters
          detractors
          passives
          skipped
        }
      }
    }
  }
`;

export default getSurveyResults;
