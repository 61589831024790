import gql from 'graphql-tag';

const getCurrentMemberProperties = gql`
  query getCurrentMemberProperties($tenantId: ID!) {
    memberProperties(tenantId: $tenantId) {
      currentCoins
      totalCoins
      rewardsCountry
      birthday
      hiredOn
      memberSettings {
        includeInFF
      }
    }
  }
`;

export default getCurrentMemberProperties;