const teamsWorkspaceFragmentName = 'teamsWorkspaceFragment';
export const TEAMS_WORKSPACE_FRAGMENT = {
  name: teamsWorkspaceFragmentName,
  fragment: `fragment ${teamsWorkspaceFragmentName} on TeamsWorkspace {
    id
    channelName
    channelId
    teamName
  }`,
};
