import gql from 'graphql-tag';

const sendCheckoutSuccess = gql`
  mutation sendCheckoutSuccess($workspaceId: ID!) {
    sendCheckoutSuccess(workspaceId: $workspaceId) {
      total
      quantity
      status
      planMoney
      amountOff
      total
      totalAfterDiscount
    }
  }
`;

export default sendCheckoutSuccess;
