import gql from 'graphql-tag';

const ADMIN_EXTEND_WORKSPACE_TRIAL = gql`
  mutation adminExtendWorkspaceTrial($tenantId: ID!, $tierExpires: CalendarDate!) {
    adminExtendWorkspaceTrial(tenantId: $tenantId, tierExpires: $tierExpires) {
      id
      name
      isTrial
      isExpired
      isFree
      expiresInDays
      tier
      tierExpires
    }
  }
`;

export default ADMIN_EXTEND_WORKSPACE_TRIAL;
