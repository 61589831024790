import gql from 'graphql-tag';

const setGeneralWorkspaceSettings = gql`
  mutation setGeneralWorkspaceSettings($workspaceId: ID!, $settings: GeneralWorkspaceSettingsInput!) {
    setGeneralWorkspaceSettings(workspaceId: $workspaceId, settings: $settings) {
      id
      timezone
      generalSettings {
        customKudosAdminOnly
        privateKudosPermission
      }
    }
  }
`;

export default setGeneralWorkspaceSettings;
