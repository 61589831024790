import gql from 'graphql-tag';

const getRewardsCountries = gql`
  query getRewardsCountries {
    rewardsCountries {
      label
      value
    }
  }
`;

export default getRewardsCountries;