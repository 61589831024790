import gql from 'graphql-tag';

const getMembersWithRecurring = gql`
  query getMembersWithRecurring($workspaceId: ID!) {
    membersWithRecurring(workspaceId: $workspaceId) {
      id
      isSelf
      person {
        id
        userId
        email
        fullName
        photoUrl
      }
      recurringFeedback {
          id
          customNote
          recurrence {
            day
            frequency
          }
          feedbackTemplate {
            id
            skills {
              id
              name
            }
          }
          nextDeliveryOn
        }
    }
  }
`;

export default getMembersWithRecurring;