import gql from 'graphql-tag';

const connectHris = gql`
  mutation connectHris($tenantId: ID!, $code: String!) {
    connectHris(tenantId: $tenantId, code: $code) {
      id
      hrisSettings {
        status
        providerDisplayName
        providerIcon
        syncingProps
        lastSync {
          date
          status
        }
      }
    }
  }
`;

export default connectHris;
