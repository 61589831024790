import gql from 'graphql-tag';

const launchPulse = gql`
  mutation launchPulse($tenantId: ID!) {
    launchPulse(tenantId: $tenantId) {
      id
      pulseSettings {
        isPulseActive
      }
    }
  }
`;

export default launchPulse;
