import gql from 'graphql-tag';

const editPulseQuestion = gql`
  mutation editPulseQuestion($tenantId: ID!, $id: ID!, $question: String!, $subcategoryId: ID, $isEnabled: Boolean!) {
    editPulseQuestion(tenantId: $tenantId, id: $id, question: $question, subcategoryId: $subcategoryId, isEnabled: $isEnabled) {
      id
      question
      category {
        id
        name
        isEnabled
      }
      isEnabled
      subcategory {
        id
        name
      }
    }
  }
`;

export default editPulseQuestion;
