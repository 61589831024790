import gql from 'graphql-tag';
import { CUSTOM_REWARD_FRAGMENT } from './fragments';

const getAllCustomRewards = gql`
  ${CUSTOM_REWARD_FRAGMENT.fragment}

  query getAllCustomRewards($tenantId: ID!) {
    getAllCustomRewards(tenantId: $tenantId) {
      ...${CUSTOM_REWARD_FRAGMENT.name}
    }
  }
`;

export default getAllCustomRewards;