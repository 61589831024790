import gql from 'graphql-tag';

const getS3PresignedUrl = gql`
  mutation getS3PresignedUrl($prefix: String!) {
    getS3PresignedUrl(prefix: $prefix) {
      presignedUrl
      imgixPath
      awsFilePath
    }
  }
`;

export default getS3PresignedUrl;
