import gql from 'graphql-tag';

const searchWorkspaceMembers = gql`
  query searchWorkspaceMembers($workspaceId: ID, $tenantId: ID, $searchString: String!, $excludeCurrentUser: Boolean) {
    searchMembers(workspaceId: $workspaceId, tenantId: $tenantId, searchString: $searchString, excludeCurrentUser: $excludeCurrentUser){
      id
      email
      permissions {
        isAdmin
        isOwner
        channelAdminWorkspaces
      }
      hiredOn
      birthday
      person {
        id
        email
        fullName
        userId
        photoUrl
      }
      totalCoins
    }
  }
`;

export default searchWorkspaceMembers;