import gql from 'graphql-tag';

const createPulseQuestion = gql`
  mutation createPulseQuestion($tenantId: ID!, $question: String!, $categoryId: ID!, $subcategoryId: ID) {
    createPulseQuestion(tenantId: $tenantId, question: $question, categoryId: $categoryId, subcategoryId: $subcategoryId) {
      id
      question
      category {
        id
        name
        isEnabled
      }
      subcategory {
        id
        name
      }
    }
  }
`;

export default createPulseQuestion;
