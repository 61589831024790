import gql from 'graphql-tag';

const updateMemberSettings = gql`
  mutation updateMemberSettings($personId: ID!, $tenantId: ID!, $settings: MemberSettingsInput!) {
    updateMemberSettings(personId: $personId, tenantId: $tenantId, settings: $settings) {
      memberSettings {
        includeInFF
      }
    }
  }
`;

export default updateMemberSettings;
