import gql from 'graphql-tag';

const removeMaxAllotedCoins = gql`
  mutation removeMaxAllotedCoins($tenantId: ID!, $personId: ID!, $memberId: ID!) {
    removeMaxAllotedCoins(tenantId: $tenantId, personId: $personId, memberId: $memberId) {
      id
      allotedCoins
    }
  }
`;

export default removeMaxAllotedCoins;
