import gql from 'graphql-tag';

const getPulseCategoryDetails = gql`
  query pulseCategoryDetails($tenantId: ID!, $categoryId: ID!, $subcategories: [String], $startDate: String, $endDate: String, $groupBy: String) {
    pulseCategoryDetails(tenantId: $tenantId, categoryId: $categoryId, subcategories: $subcategories, startDate: $startDate, endDate: $endDate, groupBy: $groupBy) {
      name
      description
      subcategories {
        name
        score
      }
      questions {
        id
        question
        score
        distribution {
          score
          count
        }
        category {
          id
          name
        }
        subcategory {
          id
          name
        }
        commentsCount
        responsesCount
        lastAsked
      }
      score
      explanation
      datePoints
      rollingEngagementScore {
        date
        score
        startDate
        endDate
      }
    }
  }
`;

export default getPulseCategoryDetails;
