import gql from 'graphql-tag';

const getSubscriptionPlans = gql`
  query getSubscriptionPlans($workspaceId: ID!) {
    subscriptionPlans(workspaceId: $workspaceId) {
      plans {
        id
        name
        tierApplied {
          amountDecimal
          minMembers
          maxMembers
        }
        perUnit {
          amountDecimal
        }
        interval
        productId
      },
      addOns {
        id
        name
        perUnit {
          amountDecimal
        }
        interval
        productId
      }
      totalMembers
    }
  }
`;

export default getSubscriptionPlans;