import gql from 'graphql-tag';

// This query fetches current subscriptions by workspace stripe customer id, 
// cancelled subscriptions doesn't show here. Use getStripeSubscriptionBySubscriptionId 
// for cancelled subscriptions
const getStripeSubscriptionByTenantId = gql`
  query getStripeSubscriptionByTenantId($tenantId: ID!) {
    stripeSubscription(tenantId: $tenantId) {
      id
      quantity
      interval
      renewDate
      canceledAt
      status
      planMoney
      amountOff
      total
      totalAfterDiscount
      paymentMethod {
        id
        card {
          brand
          last4
        }
      }
      currentTier {
        amountDecimal
        maxMembers
        minMembers
      }
      addOns {
        id
        name
      }
    }
  }
`;

export default getStripeSubscriptionByTenantId;