import gql from 'graphql-tag';

const sendRewardRequest = gql`
  mutation requestReward($tenantId: ID!, $rewardItemId: ID!, $faceValue: Float, $coinsValue: Int!, $rewardType: String) {
    requestReward(tenantId: $tenantId, rewardItemId: $rewardItemId, faceValue: $faceValue, coinsValue: $coinsValue, rewardType: $rewardType ) {
      id
      name
      status
      date
      coinsValue
    }
  }
`;

export default sendRewardRequest;