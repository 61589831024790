import gql from 'graphql-tag';

const updateRewardsSettings = gql`
  mutation setRewardsSettings($tenantId: ID!, $settings: RewardsSettingsInput!) {
    setRewardsSettings(tenantId: $tenantId, settings: $settings) {
      id
      rewardsSettings {
        isRewardsActive
        isStripePaymentFailed
        maxAllotedCoinPerRitualPerMember
        allowanceResetFrequency
        disabledRewardTypes
        autoApprove
        notifyAdmins
        nextAllowanceResetDate
      }
      rewardsFundingSettings {
        enabled
        status
        balance
        threshold
        notifyMembers
      }
    }
  }
`;

export default updateRewardsSettings;
