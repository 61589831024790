import gql from 'graphql-tag';
import { CUSTOM_REWARD_FRAGMENT } from './fragments';

const getRewardsCatalog = gql`
  ${CUSTOM_REWARD_FRAGMENT.fragment}

  query getRewardsCatalog($tenantId: ID!, $country: String!) {
    rewardsCatalog(tenantId: $tenantId, country: $country) {
      __typename
      ... on TangoReward {
        brandId
        image
        name
        disclaimer
        description
        country
        currencyCode
        rewardType
        exchangeRate
        valueType
        items {
          id
          name
          minValue
          maxValue
          faceValue
          coinsValue
          isItemRedeemable
        }
      }
      ... on CustomReward {
        ...${CUSTOM_REWARD_FRAGMENT.name}
      }
    }
  }
`;

export default getRewardsCatalog;