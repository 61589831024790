const rewardRequestFragment = 'rewardRequestFragment';
export const REWARD_REQUEST_FRAGMENT = {
  name: rewardRequestFragment,
  fragment: `
  fragment ${rewardRequestFragment} on RewardRequest {
    id
    name
    coinsValue
    faceValue
    date
    status
    memberId
    rewardItemId
    balanceAfterRedemption
    rewardItem {
      id
      valueType
      currencyCode
    }
    customRewardItem {
      id
      quantityType
      quantity
    }
    requesterUser {
      id
      firstName
      lastName
      photoUrl
    }
    __typename
  }`,
};

const customRewardFragment = 'customRewardFragment';
export const CUSTOM_REWARD_FRAGMENT = {
  name: customRewardFragment,
  fragment: `
  fragment ${customRewardFragment} on CustomReward {
    id
    name
    description
    value
    imageUrl
    quantityType
    quantity
    rewardType
  }
  `
};