import gql from 'graphql-tag';

const getEnpsDetails = gql`
  query enpsDetails($tenantId: ID!, $startDate: String!, $endDate: String!) {
    enpsDetails(tenantId: $tenantId, startDate: $startDate, endDate: $endDate) {
      enpsScore
      enpsExplanation
      enpsDetails {
        detractors
        promoters
        passives
        skipped
      }
      comments {
        text
        createdAt
      }
    }
  }
`;

export default getEnpsDetails;
