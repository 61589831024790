import gql from 'graphql-tag';
import { CUSTOM_REWARD_FRAGMENT } from './fragments';

const updateCustomReward = gql`
  ${CUSTOM_REWARD_FRAGMENT.fragment}

  mutation updateCustomReward(
    $id: ID!,
    $name: String,
    $description: String,
    $value: Int,
    $imageUrl: String,
    $quantityType: QuantityType,
    $quantity: Int,
    $workspaceId: ID!
  ) {
    updateCustomReward(
      id: $id,
      name: $name,
      description: $description,
      value: $value,
      imageUrl: $imageUrl,
      quantityType: $quantityType,
      quantity: $quantity
      workspaceId: $workspaceId
    ) {
      ...${CUSTOM_REWARD_FRAGMENT.name}
    }
  }
`;

export default updateCustomReward;