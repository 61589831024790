import gql from 'graphql-tag';

const saveCustomSurvey = gql`
  mutation saveCustomSurvey($tenantId: ID!, $id: ID, $survey: CustomSurveyInput!) {
    saveCustomSurvey(id: $id, tenantId: $tenantId, survey: $survey) {
      id
      name
      description
      sendOnMyBehalf
      isAnonymous
      hasRewards
      frequencyConfig {
        repeatValue
        sendOnDay {
          value
          number
          weekday
        }
        sendOnDays
      }
      sendReminder
      recipients
      sendDate
      frequency
      coins
      closingDate
      timezone
      notifications {
        response
        digest
      }
      questions {
        id
        type
        question
        answerOptions {
          label
        }
        canSkip
        hasComment
      }
    }
  }
`;

export default saveCustomSurvey;
