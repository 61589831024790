import gql from 'graphql-tag';

const getTenantPulseCategories = gql`
  query getTenantPulseCategories($tenantId: ID!) {
    tenantPulseCategories(tenantId: $tenantId) {
      id
      createdAt
      name
      isEnabled
      subcategories {
        id
        name
      }
    }
  }
`;

export default getTenantPulseCategories;
