import gql from 'graphql-tag';

const getNextFeedbackFriday = gql`
  query getNextFeedbackFriday($workspaceId: ID!, $settings: FeedbackRitualWorkspaceSettingsInput, $timezone: String) {
    nextFeedbackFriday(workspaceId: $workspaceId, settings: $settings, timezone: $timezone) {
      dateString
    }
  }
`;

export default getNextFeedbackFriday;