import gql from 'graphql-tag';

const getMembers = gql`
  query getMembers($tenantId: ID, $workspaceId: ID, $limit: Int, $skip: Int) {
    workspaceMembers(tenantId: $tenantId, workspaceId: $workspaceId, limit: $limit, skip: $skip) {
      cursor
      total
      members {
        id
        status
        isSelf
        updatedAt
        hiredOn
        birthday
        permissions {
          isAdmin
          isOwner
          channelAdminWorkspaces
        }
        person {
          id
          userId
          email
          fullName
          photoUrl
        }
        totalCoins
      }
    }
  }
`;

export default getMembers;