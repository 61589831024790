import gql from 'graphql-tag';

const approveAll = gql`
  mutation approveAll($workspaceId: ID!) {
    approveAll(workspaceId: $workspaceId) {
      balanceAfterRedemption
    }
  }
`;

export default approveAll;