import gql from 'graphql-tag';

const updateTenant = gql`
  mutation updateTenant($tenantId: ID!, $name: String!, $icon: String) {
    updateTenant(tenantId: $tenantId, name: $name, icon: $icon) {
      id
      name
      photoUrl
    }
  }
`;

export default updateTenant;
