import gql from 'graphql-tag';

const createPulseCategory = gql`
  mutation createPulseCategory($tenantId: ID!, $name: String!, $isEnabled: Boolean!) {
    createPulseCategory(tenantId: $tenantId, name: $name, isEnabled: $isEnabled) {
      id
      name
      isEnabled
    }
  }
`;

export default createPulseCategory;
