import gql from 'graphql-tag';

const updatePulseSettings = gql`
  mutation setPulseSettings($tenantId: ID!, $settings: PulseSettingsInput!) {
    setPulseSettings(tenantId: $tenantId, settings: $settings) {
      id
      __typename
      pulseSettings {
        isPulseEnabled
        isPulseActive
        isPulseUnlocked
        frequency
        questionCount
        eNpsFrequency
        eNpsEnabled
        adminNotifications {
          newFeedback
          digest
        }
        notifyMembers
        coinsPerSurvey
        excludeMembers
        launchSurveysInstantly
        sendReminders
        sendToAll
        recipients
        rewardsActive
      }
    }
  }
`;

export default updatePulseSettings;
