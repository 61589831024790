import gql from 'graphql-tag';

const ADMIN_GET_WORKSPACE = gql`
  query adminGetWorkspace($workspaceId: ID!, $limit: Int, $skip: Int) {
    adminGetWorkspace(workspaceId: $workspaceId) {
      id
      createdAt
      name
      subscriptions
      isDeleted
      isTrial
      isExpired
      isFree
      expiresInDays
      isEnterprise
      activeMembersCount
      rewardsSettings {
        isRewardsActive
        isStripePaymentFailed
        maxAllotedCoinPerRitualPerMember
        allowanceResetFrequency
        disabledRewardTypes
        autoApprove
        notifyAdmins
        nextAllowanceResetDate
      }
      rewardsFundingSettings {
        enabled
        status
        balance
        threshold
        notifyMembers
      }
      tier
      tierExpires
      members(limit: $limit, skip: $skip) {
        id
        email
        status
        permissions {
          isAdmin
          isOwner
        }
        updatedAt
        person {
          id
          userId
          email
          fullName
        }
        token
      }
      workspaces {
        id
        isDeleted
        createdAt
        slackWorkspace {
          id
          teamName
          primaryChannel
          slackTeamId
          totalMembers
        }
        teamsWorkspace {
          id
          teamName
          channelName
        }
        timezone
      }
    }
  }
`;

export default ADMIN_GET_WORKSPACE;
