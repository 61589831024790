import gql from 'graphql-tag';

const updateSubscriptionForPlanChange = gql`
  mutation updateSubscriptionForPlanChange($tenantId: ID!, $plan: String!) {
    updateSubscriptionForPlanChange(tenantId: $tenantId, plan: $plan) {
      id
      quantity
      interval
      renewDate
      canceledAt
      status
      planMoney
      totalAfterDiscount
      amountOff
    }
  }
`;

export default updateSubscriptionForPlanChange;