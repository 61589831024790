import gql from 'graphql-tag';
import { REWARD_REQUEST_FRAGMENT } from './fragments';

const approveReward = gql`
  ${REWARD_REQUEST_FRAGMENT.fragment}

  mutation approveReward($rewardRequestId: ID!, $workspaceId: ID!) {
    approveReward(rewardRequestId: $rewardRequestId, workspaceId: $workspaceId) {
      ...${REWARD_REQUEST_FRAGMENT.name}
    }
  }
`;

export default approveReward;