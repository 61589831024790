import gql from 'graphql-tag';

const getTenantAdmins = gql`
  query getTenantAdmins($tenantId: ID!) {
    tenantAdmins(tenantId: $tenantId) {
      id
      email
      personId
      person {
        fullName
      }
    }
  }
`;

export default getTenantAdmins;
