import gql from 'graphql-tag';

const getRewardsHistory = gql`
  query getRewardsHistory($tenantId: ID!) {
    getRewardsHistory(tenantId: $tenantId) {
      id
      name
      coinsValue
      date
      status
    }
  }
`;

export default getRewardsHistory;