import gql from 'graphql-tag';

const transferWorkspaceOwnership = gql`
  mutation transferWorkspaceOwnership($memberId: ID!, $tenantId: ID!) {
    transferWorkspaceOwnership(memberId: $memberId, tenantId: $tenantId) {
      id
      permissions {
        isAdmin
        isOwner
      }
    }
  }
`;

export default transferWorkspaceOwnership;
