import gql from 'graphql-tag';
import { REWARD_REQUEST_FRAGMENT } from './fragments';

const getAllRewardRequests = gql`
  ${REWARD_REQUEST_FRAGMENT.fragment}
  query getAllRewardRequests($tenantId: ID!) {
    getAllRewardRequests(tenantId: $tenantId) {
      pendingRequests {
        ...${REWARD_REQUEST_FRAGMENT.name}
      }
      approvedRequests {
        ...${REWARD_REQUEST_FRAGMENT.name}
      }
      declinedRequests {
        ...${REWARD_REQUEST_FRAGMENT.name}
      }
    }
  }
`;

export default getAllRewardRequests;