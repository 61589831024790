import gql from 'graphql-tag';

const updateMemberProperties = gql`
  mutation updateMemberProperties($personId: ID!, $tenantId: ID!, $hiredOn: String, $birthday: String, $totalCoins: Int) {
    updateMemberProperties(personId: $personId, hiredOn: $hiredOn, birthday: $birthday, totalCoins: $totalCoins, tenantId: $tenantId) {
      hiredOn
      totalCoins
      birthday
    }
  }
`;

export default updateMemberProperties;
