import gql from 'graphql-tag';

const updateMaxAllotedCoins = gql`
  mutation updateMaxAllotedCoins($tenantId: ID!, $memberIds: [ID!]!, $maxCoins: Int!) {
    updateMaxAllotedCoins(tenantId: $tenantId, memberIds: $memberIds, maxCoins: $maxCoins) {
      id
      allotedCoins
    }
  }
`;

export default updateMaxAllotedCoins;
