import gql from 'graphql-tag';

const createPulseSubcategory = gql`
  mutation createPulseSubcategory($tenantId: ID!, $name: String!, $categoryId: ID!) {
    createPulseSubcategory(tenantId: $tenantId, name: $name, categoryId: $categoryId) {
      id
      name
    }
  }
`;

export default createPulseSubcategory;
