import gql from 'graphql-tag';

const getTenantPulseQuestions = gql`
  query getTenantPulseQuestions($tenantId: ID!) {
    tenantPulseQuestions(tenantId: $tenantId) {
      id
      question
      category {
        id
        name
        isEnabled
      }
      isEnabled
      subcategory {
        id
        name
      }
    }
  }
`;

export default getTenantPulseQuestions;
