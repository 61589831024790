import gql from 'graphql-tag';

const sendCelebrationSpreadsheet = gql`
  mutation sendCelebrationSpreadsheet($workspaceId: ID!, $data: String!) {
    sendCelebrationSpreadsheet(workspaceId: $workspaceId, data: $data) {
      ok
      errorMessage
      processed
    }
  }
`;

export default sendCelebrationSpreadsheet;
