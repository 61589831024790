import gql from 'graphql-tag';

const getUserSurvey = gql`
  query getUserSurvey($surveyId: ID!) {
    survey(surveyId: $surveyId) {
      id
      name
      type
      status
      customSurveyId
      questions {
        id
        type
        question
        category {
          name
        }
        answerOptions {
          label
          value
        }
        canSkip
        hasComment
      }
    }
  }
`;

export default getUserSurvey;
