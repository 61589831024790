import gql from 'graphql-tag';

const getMembersWithCustomCoins = gql`
  query getMembersWithCustomCoins($tenantId: ID!) {
    membersWithCustomCoins(tenantId: $tenantId) {
      id
      person {
        id
        userId
        email
        fullName
        photoUrl
      }
      allotedCoins
    }
  }
`;

export default getMembersWithCustomCoins;