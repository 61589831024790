import gql from 'graphql-tag';

const adminAddRewardsFunds = gql`
  mutation adminAddRewardsFunds($tenantId: ID!, $workspaceId: ID!, $amount: Int!, $note: String, $isCredit: Boolean) {
    adminAddRewardsFunds(tenantId: $tenantId, workspaceId: $workspaceId, amount: $amount, note: $note, isCredit: $isCredit) {
      id
      amount
      status
      note
      paymentId
      addedBy {
        id
        fullName
      }
      approvedBy {
        id
        fullName
      }
    }
  }
`;

export default adminAddRewardsFunds;
