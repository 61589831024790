import gql from 'graphql-tag';

// This query fetches subscriptions even if cancelled
// Should we consolidate this with getStripeSubscriptionByWorkspaceId?
// May need to update db to include a subscription id column
const getStripeSubscriptionBySubscriptionId = gql`
  query getStripeSubscriptionBySubscriptionId($subscriptionId: String!) {
    getStripeSubscriptionBySubscriptionId(subscriptionId: $subscriptionId) {
      id
      quantity
      interval
      renewDate
      canceledAt
      status
      planMoney
      amountOff
    }
  }
`;

export default getStripeSubscriptionBySubscriptionId;