import gql from 'graphql-tag';

const getTenantMembersByPersonIds = gql`
  query getTenantMembersByPersonIds($personIds: [ID!]!, $tenantId: ID!) {
    tenantMembersByPersonIds(tenantId: $tenantId, personIds: $personIds) {
      id
      person {
        id
        userId
        email
        fullName
        photoUrl
      }
    }
  }
`;

export default getTenantMembersByPersonIds;