import gql from 'graphql-tag';

const validatePromoCode = gql`
  query validatePromoCode($promocode: ID!, $productIds: [String!]!, $planIds: [ID!]!, $totalMembers: Int!) {
    validatePromoCode(promocode: $promocode, productIds: $productIds, planIds: $planIds, totalMembers: $totalMembers) {
      id
      isValid
      errorMessage
      amountOff
      percentOff
    }
  }
`;

export default validatePromoCode;