import gql from 'graphql-tag';

const getIndividualSurveyResults = gql`
  query getIndividualSurveyResults($tenantId: ID!, $surveyId: ID!) {
    individualSurveyResults(tenantId: $tenantId, surveyId: $surveyId) {
      id
      responder {
        fullName
      }
      createdAt
      response {
        questionId
        comment
        answers
      }
    }
  }
`;

export default getIndividualSurveyResults;
