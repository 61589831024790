import gql from 'graphql-tag';

const sendCelebrationMessageSample = gql`
  mutation sendCelebrationMessageSample(
    $workspaceId: ID!
    $type: CelebrationType!
  ) {
    sendCelebrationMessageSample(
      workspaceId: $workspaceId
      type: $type
    )
  }
`;

export default sendCelebrationMessageSample;
