import gql from 'graphql-tag';

const syncHris = gql`
  mutation syncHris($tenantId: ID!) {
    syncHris(tenantId: $tenantId) {
      id
      hrisSettings {
        status
        providerDisplayName
        providerIcon
        syncingProps
        lastSync {
          date
          status
        }
      }
    }
  }
`;

export default syncHris;
