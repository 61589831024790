import gql from 'graphql-tag';

const updateMemberPermissions = gql`
  mutation updateMemberPermissions(
    $memberId: ID!
    $isAdmin: Boolean!
    $workspaceId: ID!
  ) {
    updateMemberPermissions(
      memberId: $memberId
      isAdmin: $isAdmin
      workspaceId: $workspaceId
    ) {
      id
      permissions {
        isAdmin
      }
    }
  }
`;

export default updateMemberPermissions;
