import gql from 'graphql-tag';
import { CUSTOM_REWARD_FRAGMENT } from './fragments';

const createCustomReward = gql`
  ${CUSTOM_REWARD_FRAGMENT.fragment}

  mutation createCustomReward(
    $workspaceId: ID!,
    $name: String!,
    $description: String,
    $value: Int!,
    $imageUrl: String!,
    $quantityType: QuantityType!,
    $quantity: Int
  ) {
    createCustomReward(
      workspaceId: $workspaceId,
      name: $name,
      description: $description,
      value: $value,
      imageUrl: $imageUrl,
      quantityType: $quantityType,
      quantity: $quantity
    ) {
      ...${CUSTOM_REWARD_FRAGMENT.name}
    }
  }
`;

export default createCustomReward;