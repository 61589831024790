import gql from 'graphql-tag';

const ADMIN_GET_MEMBER_DETAILS = gql`
  query adminGetMemberDetails($memberEmail: String!) {
    adminGetMemberDetails(memberEmail: $memberEmail) {
      id
      status
      email
      isSelf
      updatedAt
      person {
        id
        userId
        email
        fullName
        deletedAt
      }
      tenant {
        id
        name
        isAdmin
        isOwner
        isEnterprise
        isExpired
        expiresInDays
        tier
        tierExpires
        workspaces {
          id
        }
      }
    }
  }
`;

export default ADMIN_GET_MEMBER_DETAILS;
