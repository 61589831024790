import gql from 'graphql-tag';

const getMemberById = gql`
  query getMemberById($id: ID!, $workspaceId: ID!) {
    workspaceMember(workspaceId: $workspaceId, id: $id) {
      id
      email
      permissions {
        isAdmin
        isOwner
        channelAdminWorkspaces
      }
      person {
        id
        userId
        email
        fullName
        photoUrl
      }
      isSelf
      hiredOn
      birthday
      totalCoins
    }
  }
`;

export default getMemberById;