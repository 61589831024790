import gql from 'graphql-tag';

const adminUpdateTenantSubscriptions = gql`
  mutation adminUpdateTenantSubscriptions($tenantId: ID!, $subscriptions: [String]!) {
    adminUpdateTenantSubscriptions(tenantId: $tenantId, subscriptions: $subscriptions) {
      id
      subscriptions
    }
  }
`;

export default adminUpdateTenantSubscriptions;