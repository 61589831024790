import gql from 'graphql-tag';

const getHrisSyncHistory = gql`
  query getHrisSyncHistory($tenantId: ID!) {
    hrisSyncHistory(tenantId: $tenantId) {
      id
      date
      timestamp
      status
      changes
    }
  }
`;

export default getHrisSyncHistory;
