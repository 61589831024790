import gql from 'graphql-tag';

const getCelebrationSummary = gql`
  query getCelebrationSummary($workspaceId: ID!) {
    workspace(workspaceId: $workspaceId) {
      id
      activeMembersCount
      birthdayEnteredCount
      hiredOnEnteredCount
    }
  }
`;

export default getCelebrationSummary;
