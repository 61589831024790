import gql, { enableExperimentalFragmentVariables } from 'graphql-tag';
import {
  WORKSPACE_MEMBERS_BASIC_FRAGMENT,
  FEEDITEM_COMMON_FIELDS_FRAGMENT,
} from '../fragments';

enableExperimentalFragmentVariables();

/*****************************************************************************/
/* WORKSPACES QUERIES                                                        */
/*****************************************************************************/
export const WORKSPACE_INVITER_QUERY = gql`
  query($workspaceId: ID!) {
    workspaceInviter(workspaceId: $workspaceId) {
      email
      id
      fullName
      firstName
    }
  }
`;

export const ADD_WORKSPACE_MEMBERS_MUTATION = gql`
mutation addWorkspaceMembers(
  $workspaceId: ID!
  $emails: [String!]
  $source: String!
  $inviteAll: Boolean
) {
  addWorkspaceMembers(workspaceId: $workspaceId, emails: $emails, source: $source, inviteAll: $inviteAll)
}
`;

export const LEAVE_WORKSPACE_MUTATION = gql`
  mutation leaveWorkspace($workspaceId: ID!) {
    leaveWorkspace(workspaceId: $workspaceId)
  }
`;

export const SEND_WORKSPACE_DELETION_LINK_MUTATION = gql`
  mutation sendWorkspaceDeletionLink($workspaceId: ID!) {
    sendWorkspaceDeletionLink(workspaceId: $workspaceId)
  }
`;

export const DELETE_WORKSPACE_MUTATION = gql`
  mutation deleteWorkspace($workspaceId: ID!, $token: String) {
    deleteWorkspace(workspaceId: $workspaceId, token: $token)
  }
`;

export const ACCEPT_WORKSPACE_INVITATION_MUTATION = gql`
${WORKSPACE_MEMBERS_BASIC_FRAGMENT.fragment}

mutation acceptWorkspaceInvitation($token: String) {
  acceptWorkspaceInvitation(token: $token) {
    ...${WORKSPACE_MEMBERS_BASIC_FRAGMENT.name}
  }
}
`;

export const SEND_FEEDBACK_RITUAL_WORKSPACE_MESSAGE_SAMPLE = gql`
  mutation sendFeedbackRitualMessageSample(
    $type: RitualMessageType!
    $workspaceId: ID!
    $settings: FeedbackRitualSampleSettingsInput!
  ) {
    sendFeedbackRitualMessageSample(
      type: $type
      workspaceId: $workspaceId
      settings: $settings
    )
  }
`;

export const GET_WORKSPACE_BY_MEMBER_TOKEN_QUERY = gql`
  query($token: String!) {
    workspaceInvitationByMemberToken(token: $token) {
      workspace {
        id
        isDeleted
        name
        iconUrl
        members {
          person {
            email
            fullName
          }
          status
          updatedAt
        }
      }
      workspaceMember {
        id
        token
        person {
          email
        }
        updatedAt
      }
    }
  }
`;

export const LOGOUT_AND_CHECK_FOR_USER_FROM_MEMBER_TOKEN_MUTATION = gql`
  mutation($token: String!) {
    logoutAndCheckForUserFromMemberToken(token: $token) {
      id
      email
      isOnboarded
    }
  }
`;

export const WORKSPACE_FEED_QUERY = gql`
  query getWorkspaceFeed($workspaceId: ID!, $offset: Int) {
    workspaceFeed(workspaceId: $workspaceId, offset: $offset) {
      count
      feedItems {
        id
        canDelete
        isMultipleReceiver
        isWholeChannel
        isAdmin
        eventTime
        comments {
          id
          author {
            id
            fullName
            firstName
            photoUrl
            isSelf
          }
          createdAt
          message
          canDelete
        }
        numberComments
        thanks {
          canThank
          canSeeThank
          thanks {
            id
            author {
              id
              fullName
              firstName
              photoUrl
              isSelf
            }
            createdAt
            canDelete
          }
          numberThanks
        }
        payload {
          __typename
          ... on WorkspaceCreation {
            workspaceCreator {
              id
              fullName
              firstName
              email
              photoUrl
            }
          }
          ... on WorkspaceMemberAdded {
            membersAdded {
              joinedAt
              member {
                id
                fullName
                firstName
                email
                photoUrl
              }
            }
          }
          ... on KudosPayload {
						kudos {
              type
              note
            }
            receivers {
              id
              fullName
              firstName
              email
              photoUrl
            }
            giver {
              id
              fullName
              firstName
              email
              photoUrl
            }
      	  }
          ... on RitualStart {
            ritualName
            mainMessage
            secondaryMessage
          }
          ... on RitualProgress {
            ritualName
            mainMessage
            secondaryMessage
            percentProgress
          }
          ... on RitualRecap {
            ritualName
            mainMessage
            secondaryMessage
            feedbackGivers {
              id
              fullName
              firstName
              email
              photoUrl
            }
            biggestGivers {
              id
              fullName
              firstName
              email
              photoUrl
            }
          }
        }
      },
    }
  }
`;

export const HAS_ACTIVE_STRIPE_SUBSCRIPTION = gql`
  mutation($workspaceId: ID!) {
    hasActiveStripeSubscription(workspaceId: $workspaceId)
  }
`;

export const STRIPE_PAYMENT_STATUS = gql`
  mutation($sessionId: String!) {
    stripePaymentStatus(sessionId: $sessionId)
  }
`;

export const CREATE_PORTAL_SESSION = gql`
  mutation($workspaceId: ID!) {
    createPortalSession(workspaceId: $workspaceId)
  }
`;

export const SEND_UPGRADE_WORKSPACE_REQUEST = gql`
  mutation sendUpgradeRequest($workspaceId: ID!, $workspaceAdminId: ID, $message: String, $isOutOfKudos: Boolean) {
    sendUpgradeWorkspaceRequest(workspaceId: $workspaceId, workspaceAdminId: $workspaceAdminId, message: $message, isRewardsRequest: false, isOutOfKudos: $isOutOfKudos) {
      id
    }
  }
`;

export const SEND_ENABLE_REWARDS_REQUEST = gql`
  mutation sendEnableRewardsRequest($workspaceId: ID!, $workspaceAdminId: ID, $message: String) {
    sendUpgradeWorkspaceRequest(workspaceId: $workspaceId, workspaceAdminId: $workspaceAdminId, message: $message, isRewardsRequest: true) {
      id
    }
  }
`;

// TODO: remove this mutation and its usage (removed from BE)
export const WORKSPACE_INVITATION_SUGGESTIONS = gql`
  mutation($workspaceId: ID!, $nameOrEmail: String!) {
    invitationSuggestions(workspaceId: $workspaceId, nameOrEmail: $nameOrEmail) {
      slack {
        id
        fullName
        email
      }
      contacts {
        userId
        fullName
        email
      }
    }
  }
`;

export const FEEDBACKS_FEED_QUERY = gql`
${FEEDITEM_COMMON_FIELDS_FRAGMENT.fragment}

query($skillIds: [ID!], $userIds: [ID!], $type: String, $offset: Int, $workspaceIds: [ID]!) {
  feedbacksFeed(skillIds: $skillIds, userIds: $userIds, type: $type, offset: $offset, workspaceIds: $workspaceIds) {
    feedItems {
      ...${FEEDITEM_COMMON_FIELDS_FRAGMENT.name}
    }
    count
  }
}
`;

export const GET_SLACK_OAUTH_URL = gql`
query getSlackOAuthURL($inapp: Boolean!, $clientOrigin: String, $tenantId: ID) {
  slackOAuthURL(inapp: $inapp, tenantId: $tenantId, clientOrigin: $clientOrigin)
}
`;

export const CREATE_CUSTOM_KUDOS = gql`
  mutation createCustomKudos($workspaceId: ID!,
    $type: String!,
    $label: String!,
    $iconFileName: String,
    $awsFilePath: String!
    $backgroundSettings: BackgroundSettingsInput!) {
    createCustomKudos(
      workspaceId: $workspaceId,
      type: $type,
      label: $label,
      iconFileName: $iconFileName,
      awsFilePath: $awsFilePath,
      backgroundSettings: $backgroundSettings) {
        id
        createdAt
        type
        label
        creatorUserId
        workspaceId
        iconFileName
        backgroundSettings {
          isLight
          textColor
          backgroundColor
          backgroundImage
          backgroundName
        }
        isCustom
      }
  }
`;

export const GET_CUSTOM_KUDOS_BY_ID = gql`
  query getCustomKudosById($id: ID!) {
    getCustomKudosById(id: $id) {
      id
      createdAt
      type
      label
      creatorUserId
      workspaceId
      iconFileName
      backgroundSettings {
        isLight
        textColor
        backgroundColor
        backgroundImage
        backgroundName
      }
      isCustom
    }
  }
`;

export const GET_ALL_CUSTOM_KUDOS_FOR_WORKSPACE = gql`
  query getAllCustomKudosForWorkspace(
    $workspaceId: ID,
    $includeDeleted: Boolean) {
    customKudos(
      workspaceId: $workspaceId,
      includeDeleted: $includeDeleted
    ) {
        id
        createdAt
        type
        label
        creatorUserId
        creatorName
        workspaceId
        iconFileName
        backgroundSettings {
          isLight
          textColor
          backgroundColor
          backgroundImage
          backgroundName
        }
        isCustom
      }
  }
`;

export const IS_EXISTING_CUSTOM_KUDOS = gql`
  mutation isExistingCustomKudosForWorkspace($workspaceId: ID!, $type: String!) {
    isExistingCustomKudosForWorkspace(workspaceId: $workspaceId, type: $type)
  }
`;

export const UPDATE_CUSTOM_KUDOS = gql`
  mutation updateCustomKudos(
    $id: ID!,
    $workspaceId: ID!,
    $label: String,
    $iconFileName: String,
    $awsFilePath: String,
    $backgroundSettings: BackgroundSettingsInput) {
    updateCustomKudos(
      id: $id,
      workspaceId: $workspaceId,
      label: $label,
      iconFileName: $iconFileName,
      awsFilePath: $awsFilePath,
      backgroundSettings: $backgroundSettings) {
      id
      createdAt
      updatedAt
      type
      label
      creatorUserId
      workspaceId
      iconFileName
      backgroundSettings {
        isLight
        backgroundColor
        textColor
        backgroundImage
        backgroundName
      }
      isCustom
    }
  }
`;

export const WORKSPACE_MEMBER_ANALYTICS = gql`
  query workspaceMemberAnalytics($workspaceId: ID!, $startDate: String!, $endDate: String!, $offset: Int, $limit: Int) {
    workspaceMemberAnalytics(workspaceId: $workspaceId, startDate: $startDate, endDate: $endDate, offset: $offset, limit: $limit) {
      userId
      fullName
      photoUrl
      feedbacksGiven
      feedbacksReceived
      kudosGiven
      kudosReceived
    }
  }
`;