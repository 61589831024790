import gql from 'graphql-tag';

const adminApprovePendingFunds = gql`
  mutation adminApprovePendingFunds($tenantId: ID!, $token: String, $recordId: ID) {
    adminApprovePendingFunds(tenantId: $tenantId, token: $token, recordId: $recordId) {
      id
      amount
      note
      paymentId
      status
      approvedBy {
        id
        fullName
      }
    }
  }
`;

export default adminApprovePendingFunds;
