import gql from 'graphql-tag';

const getCustomSurvey = gql`
  query getCustomSurvey($tenantId: ID!, $surveyId: ID!) {
    customSurvey(tenantId: $tenantId, surveyId: $surveyId) {
      id
      name
      description
      createdAt
      recipients
      sendOnMyBehalf
      isAnonymous
      hasRewards
      frequencyConfig {
        repeatValue
        sendOnDay {
          value
          number
          weekday
        }
        sendOnDays
      }
      sendReminder
      sendDate
      status
      frequency
      coins
      closingDate
      activatedOn
      sentOn
      timezone
      createdBy {
        id
        fullName
      }
      notifications {
        response
        digest
      }
      activeQuestions {
        id
        type
        question
        category {
          id
          name
          isEnps
        }
        answerOptions {
          label
          value
        }
        canSkip
        hasComment
      }
      questions {
        id
        type
        question
        category {
          id
          name
          isEnps
        }
        answerOptions {
          label
          value
        }
        canSkip
        hasComment
      }
    }
  }
`;

export default getCustomSurvey;
