import gql from 'graphql-tag';
import { TEAMS_WORKSPACE_FRAGMENT } from 'graphql-queries/fragments/workspace';

const getWorkspace = gql`
  ${TEAMS_WORKSPACE_FRAGMENT.fragment}
  query getWorkspace($workspaceId: ID!) {
    workspace(workspaceId: $workspaceId) {
      id
      name
      iconUrl
      kudosLeft
      activeMembersCount
      isChannelAdmin
      isDeleted
      slackWorkspace {
        id
        teamName
        postAsUserOAuthURL
        primaryChannel
        primaryChannelId 
        slackTeamId
        settings {
          customKudosAdminOnly
        }
      }
      tenantId
      teamsWorkspace {
        ...${TEAMS_WORKSPACE_FRAGMENT.name}
      }
      timezone
      celebrationSettings {
        isBirthdaysActive
        birthdayCoinsAwarded
        isAnniversariesActive
        anniversaryCoinsAwarded
        autoCollectBirthdays
        autoCollectAnniversaries
        birthdayMessage
        anniversaryMessage
        isBirthdaysNotifyAll
        isAnniversariesNotifyAll
        autoCollectBirthdaysStartDate
        autoCollectAnniversariesStartDate
        anniversaryCustomAwards { years, coins }
        sendOnWeekendDays
      }
      feedbackRitualSettings {
        isActive
        isReminderActive
        isRecapActive
        mpvCoinsAwarded
        dayOfPost
        kickoffMessage
        frequency
        isLastCallReminderActive
        notifyEntireTeamForLastCall
        notifyEntireTeamForKickoff
        isStatsMessageActive
        kickOffTime
        reminderTime
        lastCallTime
        recapTime
        statsTime
        excludePeopleFromMVP
      }
      generalSettings {
        customKudosAdminOnly
        privateKudosPermission
      }
    }
  }
`;

export default getWorkspace;
