import gql from 'graphql-tag';

const updateHrisSettings = gql`
  mutation updateHrisSettings($tenantId: ID!, $settings: HrisSettingsInput!) {
    updateHrisSettings(tenantId: $tenantId, settings: $settings) {
      id
      hrisSettings {
        status
        providerDisplayName
        providerIcon
        syncingProps
        lastSync {
          date
          status
        }
      }
    }
  }
`;

export default updateHrisSettings;
