import gql from 'graphql-tag';

const getCurrentUserNotifications = gql`
  query getCurrentUserNotifications {
    notifications {
      id
      isViewed
      type
    }
    feedbackReceivedFeedItems(isUnviewedOnly: true, isNonSelfOnly: true) {
      id
      workspace {
        id
      }
    }
    pendingInvitations {
      id
      isCompleted
      isIgnored
      eventTime
      path
      owner {
        id
        email
        headline
        fullName
        photoUrl
      }
    }
  }
`;

export default getCurrentUserNotifications;
