import gql from 'graphql-tag';

const getPulseQuestionDetails = gql`
  query pulseQuestionDetails($tenantId: ID!, $questionId: ID!, $startDate: String, $endDate: String, $groupBy: String) {
    pulseQuestionDetails(tenantId: $tenantId, questionId: $questionId, startDate: $startDate, endDate: $endDate, groupBy: $groupBy) {
      id
      question
      score
      explanation
      comments {
        text
        createdAt
      }
      lastAsked
      datePoints
      rollingEngagementScore {
        date
        score
        startDate
        endDate
      }
    }
  }
`;

export default getPulseQuestionDetails;
